import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import imageCompression from 'browser-image-compression';

import { useTheme, alpha, styled } from '@mui/material/styles';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MuiLink from '@mui/material/Link';

import ErrorIcon from '@mui/icons-material/Error';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import AddIcon from '@mui/icons-material/Add';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CloseIcon from '@mui/icons-material/Close';

import { toast } from 'react-toastify';

import Dropdown from '../components/Dropdown';
import DropzoneWrapper from '../components/DropzoneWrapper';
import StripedDataGrid from '../components/StripedDataGrid';
import ImageGrid from '../components/ImageGrid';
import GridToolbarQuickFilterCustom from '../components/GridToolbarQuickFilterCustom';

import Config from '../components/Config';
import Utils from './../helper/Utils';

const ServiceInspectionReport = () => {
	const theme = useTheme();

	const [serviceInspectionReportData, setServiceInspectionReportData] = useState([]);
	const [dataGridPageSize, setDataGridPageSize] = useState(25);
	const [tableData, setTableData] = useState([]);

	const [deleteReportID, setDeleteReportID] = useState();
	const [deleteFinalReportID, setDeleteFinalReportID] = useState();

	const [isAddingRecord, setIsAddingRecord] = useState(false);
	const [editReportID, setEditReportID] = useState();
	const [editReportData, setEditReportData] = useState();
	const [filterTeamID, setFilterTeamID] = useState(0);

	const [loadData, setLoadData] = useState(true);

	const [openEditModal, setOpenEditModal] = useState(false);
	const [defaultExpandItems, setDefaultExpandItems] = useState(true);
	const unitData = [
		{
			unit_name: "pwd"
		},
		{
			unit_name: "ft"
		},
	];

	// Final Report & Images Upload
	const [finalReportFile, setFinalReportFile] = useState(false);
	const [uploadReportID, setUploadReportID] = useState();
	const [uploadTabValue, setUploadTabValue] = useState((Utils.checkAccess(3)) ? 0 : 1);
	const [uploadedFinalReport, setUploadedFinalReport] = useState();
	const [uploadedImages, setUploadedImages] = useState();

	const handleOpenAddEditModal = () => {
		setIsAddingRecord(true);
		setEditReportData({
			customer_id: 1,
			items: [],
		});
		setOpenEditModal(true);
  };
	const handleOpenEditModal = (id, data) => {
		setIsAddingRecord(false);
		setEditReportID(id);

		let dataInstance = JSON.parse(JSON.stringify(data)); // Deep copy data
		setEditReportData(dataInstance);
		setOpenEditModal(true);
  };
  const handleCloseEditModal = () => {
		setEditReportID();
		setEditReportData();
    setOpenEditModal(false);
  };

	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const handleOpenDeleteDialog = (id) => {
		setDeleteReportID(id);
    setOpenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
		setDeleteReportID();
    setOpenDeleteDialog(false);
  };

	const [openUploadModal, setOpenUploadModal] = useState(false);
	const handleOpenUploadModal = (id) => {
		setUploadReportID(id);
		getFinalReport(id);
		getImages(id);
    setOpenUploadModal(true);
  };
  const handleCloseUploadModal = () => {
		setUploadReportID();
		setUploadedFinalReport();
		setUploadedImages();
		setUploadTabValue((Utils.checkAccess(3)) ? 0 : 1);
    setOpenUploadModal(false);
  };
	const handleCloseDeleteFinalReportDialog = () => {
		setDeleteFinalReportID();
  };

	let navigate = useNavigate();

	const modalStyle = {
		maxHeight: '100%',
		overflow: 'auto',
	  position: 'absolute',
	  top: '50%',
	  left: '50%',
	  transform: 'translate(-50%, -50%)',
		width: '60%',
		[theme.breakpoints.down('sm')]: {
      width: '90%',
    },
		maxWidth: '1000px',
	  p: 4,
	};

	const buttonStyle = {
		textTransform: 'none',
		width: 'auto',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	};

	const getServiceInspectionReportData = (teamID) => {

		const options = {
			method: "GET",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include'
		};

		let teamQuery = "";
		if(teamID){
			teamQuery = `?team_id=${teamID}`;
		}

		fetch(Config.server_url + 'reporting/serviceinspection' + teamQuery, options)
		.then(res => {
			if(!res.ok){
				res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
				return undefined;
			}
			return res.json();
		})
		.then(res => {
			if(res === undefined){
				return;
			}

			// Get site inspection by user id and place it in an array
			for(let item of res){
				let siteInspectionByArr = [];
				for(let siteInspectionBy of item.site_inspection_by){
					siteInspectionByArr.push(siteInspectionBy.site_inspection_by);
				}

				item.site_inspection_by_full_name = "";
				if(item.site_inspection_by.length > 0){
					item.site_inspection_by_full_name = item.site_inspection_by.map(x => x.full_name).join(', ');
				}
				item.site_inspection_by = siteInspectionByArr; // Set site inspection by to be user id array
			}

			// Clone and store the original data
			setServiceInspectionReportData(JSON.parse(JSON.stringify(res)));
			let originalData = JSON.parse(JSON.stringify(res));

			// Format data for table display
			res.forEach((part, index, arr) => {
			  arr[index].id = arr[index].service_inspection_report_id;

				// Customer
				let addressArr = [arr[index].block_no, arr[index].address, arr[index].unit_no];
				let addressArr2 = [((arr[index].postal_code) ? `, Singapore ${arr[index].postal_code}` : ""), ((arr[index].team_name) ? `, ${arr[index].team_name}` : "")];
				let addressStr = addressArr.join(' ') + addressArr2.join('');
				arr[index].address_dg = addressStr;

				arr[index].customer = {
					name: arr[index].customer_name,
					address: addressStr,
				}

				// Buttons
				arr[index].button = (
					<>
						{
							(Utils.checkAccess(3)) && (
								<IconButton aria-label="download" color="purple" size="small" onClick={() => downloadReport(arr[index].service_inspection_report_id)}>
									<DownloadIcon fontSize="small"/>
								</IconButton>
							)
						}
						<IconButton aria-label="upload" color="warning" size="small" onClick={() => handleOpenUploadModal(arr[index].service_inspection_report_id)}>
							<UploadIcon fontSize="small"/>
						</IconButton>
						<IconButton aria-label="edit" color="info" size="small" onClick={() => handleOpenEditModal(arr[index].service_inspection_report_id, originalData[index])}>
							<EditIcon fontSize="small"/>
						</IconButton>
						{
							(Utils.checkAccess(3)) && (
								<IconButton aria-label="delete" color="error" size="small" onClick={() => handleOpenDeleteDialog(arr[index].service_inspection_report_id)}>
									<DeleteIcon fontSize="small"/>
								</IconButton>
							)
						}
					</>
				)
			});

			// Display the table data
			setTableData(res);
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

	};

	const presetItems = (locationID) => {
		const options = {
			method: "GET",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include'
		};

		fetch(Config.server_url + `item/location/${locationID}?report_type=serviceinspection`, options)
		.then(res => {
			if(!res.ok){
				res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
				return undefined;
			}
			return res.json();
		})
		.then(res => {
			if(res === undefined){
				return;
			}

			let items = [];
			for(let item of res){
				items.push({
					item_id: item.item_id,
					quantity: null,
					unit: null,
				});
			}
			setEditReportData((prev) => ({...prev, items: items}))
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});
	};

	const duplicateReport = () => {
		const options = {
			method: "POST",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include',
		};

		fetch(Config.server_url + `reporting/serviceinspection/${editReportID}/duplicate`, options)
		.then(res => {
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Success
			toast.success(<div>Successfully duplicated report</div>);
			setLoadData(true);
			handleCloseEditModal();
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});
	};

	const downloadReport = (reportID) => {

		const options = {
			method: "POST",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include',
		};

		fetch(Config.server_url + `reporting/serviceinspection/${reportID}/generate`, options)
		.then(res => {
			if(!res.ok){
				res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
				return undefined;
			}

			return res.blob();
		})
		.then(blob => {
			if(blob === undefined){
				return;
			}

			var url = window.URL.createObjectURL(blob);
			window.open(url);
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});
	};

	const getFinalReport = (id) => {
		const options = {
			method: "GET",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include'
		};

		fetch(Config.server_url + `reporting/serviceinspection/${id}/finalreport`, options)
		.then(res => {
			if(!res.ok){
				res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
				return undefined;
			}
			return res.json();
		})
		.then(res => {
			if(res === undefined){
				return;
			}
			setUploadedFinalReport(res);
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});
	};

	const deleteFinalReport = (id) => {
		const options = {
			method: "DELETE",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include'
		};

		fetch(Config.server_url + `reporting/serviceinspection/${id}/finalreport`, options)
		.then(res => {
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}
			toast.success(<div>Successfully deleted final report</div>);
			getFinalReport(id);
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});
	};

	const getImages = (id) => {
		const options = {
			method: "GET",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include'
		};

		fetch(Config.server_url + `reporting/serviceinspection/${id}/image`, options)
		.then(res => {
			if(!res.ok){
				res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
				return undefined;
			}
			return res.json();
		})
		.then(res => {
			if(res === undefined){
				return;
			}
			for(let item of res){
				item.id = item.service_inspection_report_image_id;
			}
			setUploadedImages(res);
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});
	};

	const uploadFinalReport = (file) => {
		const formData = new FormData();
		formData.append('file', file);

		const options = {
			method: "POST",
			credentials: 'include',
			body: formData,
		};

		fetch(Config.server_url + `reporting/serviceinspection/${uploadReportID}/finalreport/upload`, options)
		.then(res => {
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Success
			toast.success(<div>Successfully uploaded final report</div>);
			getFinalReport(uploadReportID);
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});
	};

	const uploadImages = async (files) => {
		const formData = new FormData();

		for(let file of files){
			// Compress images before upload
			const options = {
		    maxSizeMB: 0.5,
		  }

			file = await imageCompression(file, options);
			formData.append('file', file);
		}

		const options = {
			method: "POST",
			credentials: 'include',
			body: formData,
		};

		fetch(Config.server_url + `reporting/serviceinspection/${uploadReportID}/image/upload`, options)
		.then(res => {
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Success
			toast.success(<div>Successfully uploaded image(s)</div>);
			getImages(uploadReportID);
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});
	};

	const deleteImage = (deleteReportID, imageID) => {
		const options = {
			method: "DELETE",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include'
		};

		fetch(Config.server_url + `reporting/serviceinspection/${deleteReportID}/image/${imageID}`, options)
		.then(res => {
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Success
			toast.success(<div>Successfully deleted image</div>);
			getImages(deleteReportID);
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

	};

	const addServiceInspectionReport = (e) => {
		e.preventDefault();

		const options = {
			method: "POST",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include',
			body: JSON.stringify(editReportData)
		};

		fetch(Config.server_url + 'reporting/serviceinspection/', options)
		.then(res => {
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Success
			toast.success(<div>Successfully added report</div>);
			setLoadData(true);
			handleCloseEditModal();
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

	};

	const editServiceInspectionReport = (e) => {
		e.preventDefault();

		const options = {
			method: "PUT",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include',
			body: JSON.stringify(editReportData)
		};

		fetch(Config.server_url + 'reporting/serviceinspection/' + editReportID, options)
		.then(res => {
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Success
			toast.success(<div>Successfully edited report</div>);
			setLoadData(true);
			handleCloseEditModal();
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

	};

	const deleteServiceInspectionReport = () => {

		const options = {
			method: "DELETE",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include'
		};

		fetch(Config.server_url + 'reporting/serviceinspection/' + deleteReportID, options)
		.then(res => {
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}

			// Success
			toast.success(<div>Successfully deleted report</div>);
			setLoadData(true);
			handleCloseDeleteDialog();
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

	};

	const columns = [
	  {
	    field: 'serial_no',
	    headerName: 'Serial No.',
			renderCell: (params) => {
				if(params.row.final_report_uploaded){
					return (<span style={{color: 'green'}}>{params.row.serial_no}</span>);
				} else {
					return params.row.serial_no;
				}
			}
	  },
	  {
	    field: 'customer',
	    headerName: 'Customer',
			flex: 1,
			minWidth: 150,
			renderCell: (params) => (
	      <div>
	        <b>{params.row.customer.name}</b><br/>
	        {params.row.customer.address}
	      </div>
	    ),
			valueGetter: (params) => `${params.row.customer.name} ${params.row.customer.address}`
	  },
		{
	    field: 'remarks',
	    headerName: 'Remarks',
	  },
		{
	    field: 'site_inspection_by_full_name',
	    headerName: 'Site Inspection',
			flex: 1,
			minWidth: 150,
	  },
		{
	    field: 'button',
	    headerName: '',
			sortable: false,
			flex: 1,
			align: 'right',
			minWidth: 150,
	    renderCell: (field) => (
				<>
						{field.value}
	      </>
	    ),
	  },
	];

	useEffect(() => {
		if(loadData) {
			getServiceInspectionReportData();
			setLoadData(false);
		}
	});

	return (
		<>
		<Grid
			container
			spacing={2}
			>
			<Grid container item xs={12}>
				<Grid item xs={12} md={8}>
					<Typography variant="h6" noWrap component="div">
						<b>Service Inspection Reports</b>
					</Typography>
				</Grid>
				<Grid container item xs={12} md={4} justifyContent="flex-end">
				{
					(Utils.checkAccess(3)) && (
						<Button
							color="success"
							variant="pill"
							disableElevation
							sx={buttonStyle}
							onClick={(e) => handleOpenAddEditModal()}
							>
							<AddIcon fontSize="small"/> Add Report
						</Button>
					)
				}
				</Grid>
			</Grid>
			{
				(Utils.checkAccess(3)) && (
					<Grid item xs={12}>
						<Dropdown
							dataUrl="team"
							label="Filter by Team"
							keyField="team_id"
							valueField="team_name"
							fullWidth
							default={{key: 0, value: "ALL"}}
							value={filterTeamID}
							onChange={(e) => {
								setFilterTeamID(e.target.value);
								getServiceInspectionReportData(e.target.value);
							}}
							/>
					</Grid>
				)
			}
			<Grid item xs={12}>
				<div style={{ height: '80vh' }}>
					<StripedDataGrid
		        rows={tableData}
		        columns={columns}
						pageSize={dataGridPageSize}
						onPageSizeChange={(newPageSize) =>
        			setDataGridPageSize(newPageSize)
    				}
						pagination
		        rowsPerPageOptions={[25, 50, 100]}
						getRowHeight={() => "auto"}
						sx={{
					    '.MuiDataGrid-columnSeparator': {
					      display: 'none',
					    },
					    '&.MuiDataGrid-root': {
					      border: 'none',
					    },
							'& .MuiDataGrid-cell': {
	            	py: '5px',
	            },
					  }}
						getRowClassName={(params) =>
		          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
		        }
						components={{ Toolbar: GridToolbarQuickFilterCustom }}
		      />
				</div>
			</Grid>
		</Grid>

		{/* Upload form */}
		<Modal
			open={openUploadModal}
			onClose={handleCloseUploadModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Paper sx={modalStyle}>
				{/* Tabs */}
				<Box sx={{ width: '100%', mb: 4 }}>
					<Tabs
						value={uploadTabValue}
						onChange={(event, newValue) => {
					    setUploadTabValue(newValue);
					  }}
						centered>
						<Tab value={0} label="Final Report" disabled={!Utils.checkAccess(3)}/>
						<Tab value={1} label="Images" />
					</Tabs>
				</Box>

				{
					(uploadTabValue === 0) ? (
						<Grid
							container
							spacing={0}
							alignItems="center"
							justifyContent="center"
						>
							<Grid item xs={12} sx={{mb: 3}}>
								<DropzoneWrapper
									onDrop={(files) => uploadFinalReport(files[0])}
									multiple={false}
									acceptPdfAndImage
								/>
							</Grid>
							<Grid
								container
								item
								alignItems="center"
								justifyContent="center"
								xs={12}
							>
								<InsertDriveFileIcon fontSize='large' sx={{color: '#212020'}} />
								{(uploadedFinalReport && uploadedFinalReport.url) ? (
										<Box>
											<MuiLink href={uploadedFinalReport.url} target="_blank">
												{uploadedFinalReport.filename}
											</MuiLink>
											<IconButton
												aria-label="close"
												color="trans"
												size="small"
												onClick={() => setDeleteFinalReportID(uploadReportID)}
											>
												<CloseIcon fontSize="small" />
											</IconButton>
										</Box>
									) : (
										<p style={{margin: 0}}>No uploaded file</p>
									)
								}
							</Grid>
						</Grid>
					) :
					(uploadTabValue === 1) ? (
						<Grid
							container
							spacing={0}
							alignItems="center"
							justifyContent="center"
						>
							<Grid item xs={12} sx={{mb: 3}}>
								<DropzoneWrapper
									onDrop={(files) => uploadImages(files)}
									multiple={true}
									message={"Drop or select here to upload images"}
									acceptImage
								/>
							</Grid>
							<Grid
								container
								item
								alignItems="center"
								justifyContent="center"
								xs={12}
							>
								<ImageGrid
									images={uploadedImages}
									onDeleteImage={(id) => deleteImage(uploadReportID, id)}
									disableDelete={!Utils.checkAccess(3)}
								/>
							</Grid>
						</Grid>
					) :
					(<></>)
				}
				<Grid container item xs={12} justifyContent="flex-end" sx={{mt: 2}}>
					<Button
						type="button"
						color="info"
						disableElevation
						variant="contained"
						sx={{textTransform: 'none', marginLeft: 1}}
						onClick={handleCloseUploadModal}
						>
						Close
					</Button>
				</Grid>
			</Paper>
		</Modal>

		{/* Delete Final Report Dialog */}
		<Dialog
				open={deleteFinalReportID != undefined}
				onClose={handleCloseDeleteFinalReportDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
			<DialogTitle id="alert-dialog-title">
				{"Are you sure?"}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					This action cannot be undone.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					color="error"
					onClick={() => {
						deleteFinalReport(deleteFinalReportID);
						handleCloseDeleteFinalReportDialog();
					}}
					sx={{textTransform: 'none'}}
				>
					Delete
				</Button>
				<Button onClick={handleCloseDeleteFinalReportDialog} autoFocus sx={{textTransform: 'none'}}>
					Close
				</Button>
			</DialogActions>
		</Dialog>

		{/* Delete Dialog */}
		<Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="contained" disableElevation onClick={deleteServiceInspectionReport} sx={{textTransform: 'none'}}>Delete</Button>
          <Button onClick={handleCloseDeleteDialog} autoFocus sx={{textTransform: 'none'}}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

			{/* Add/Edit form */}
			<Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={modalStyle}>
					<Grid container sx={{mb: 2}}>
						<Grid item xs={12} md={6}>
		          <Typography id="modal-modal-title" variant="h6" component="h2">
		            {(isAddingRecord) ? "Add Report" : "Edit Report"}
		          </Typography>
						</Grid>
						{
							(!isAddingRecord) && (
								<Grid container item xs={12} md={6} justifyContent="flex-end">
									<Button
										type="button"
										color="warning"
										variant="outlined"
										disableElevation
										sx={{textTransform: 'none'}}
										disabled={!Utils.checkAccess(3)}
										onClick={() => duplicateReport()}
										>
										<FileCopyIcon fontSize="small" sx={{mr: 1}}/> Duplicate Report
									</Button>
								</Grid>
							)
						}
					</Grid>

					<Box
						component="form"
						autoComplete="true"
						onSubmit={(isAddingRecord) ? addServiceInspectionReport : editServiceInspectionReport}
					>
						<Grid
							container
							spacing={2}
							>
							<Grid item xs={12}>
								<Dropdown
									dataUrl="customer"
									label="Customer"
									keyField="customer_id"
									valueField="customer_name"
									fullWidth
									disabled={!Utils.checkAccess(3)}
									value={(editReportData && editReportData.customer_id) ? editReportData.customer_id : ""}
									onChange={(e) => setEditReportData((prev) => ({...prev, customer_id: e.target.value}))}
									/>
							</Grid>
							<Grid item xs={12}>
								{
									(editReportData) && (
										<Dropdown
											dataUrl={"location/customer/" + editReportData.customer_id}
											label="Location"
											keyField="location_id"
											valueField="address"
											fullWidth
											disabled={!Utils.checkAccess(3)}
											value={editReportData.location_id || ""}
											onChange={(e) => {
												if(isAddingRecord) { // If adding record, set the preset of all items
													presetItems(e.target.value);
												}
												setEditReportData((prev) => ({...prev, location_id: e.target.value}))
										}}
											/>
									)
								}
							</Grid>
							<Grid item xs={12}>
								<Accordion
									defaultExpanded={defaultExpandItems}
									TransitionProps={{ unmountOnExit: true }}
								>
					        <AccordionSummary
					          expandIcon={<ExpandMoreIcon />}
					          aria-controls="items-content"
					          id="items-header"
					        >
										<Grid container>
											<Grid item xs={12} md={4}>
												<Typography variant="label" component="label">
													Items
												</Typography>
											</Grid>
											<Grid container item xs={12} md={8} justifyContent={{sm: "flex-start", md: "flex-end"}}>
												<Typography
													variant="label"
													component="label"
													sx={{mr: 1, color: theme.palette.hint.main}}
												>
													<small>Select to expand or collapse</small>
												</Typography>
											</Grid>
										</Grid>
					        </AccordionSummary>
					        <AccordionDetails>
										<Grid container>
											<Grid container item xs={12} justifyContent="flex-end" sx={{mb: 1}}>
											{
												(Utils.checkAccess(3)) && (
													<Button
														color="success"
														disableElevation
														sx={{textTransform: 'none'}}
														onClick={(e) => {
															editReportData.items.push({
																item_id: undefined,
																quantity: undefined,
															});
															setEditReportData((prev) => ({...prev, items: editReportData.items}))
														}}
														>
														<AddIcon fontSize="small"/> Add New
													</Button>
												)
											}
											</Grid>
											<Grid item xs={12}>
											{
												(editReportData) && (
													editReportData.items.map((item, index) =>
													(!item.delete) &&
													(
														<Paper key={index} elevation={0} sx={{border: '0.5px solid #BFBABA', mb: 2, p: 2}}>
															<Grid container spacing={2}>
																<Grid item xs={12} md={(Utils.checkAccess(3)) ? 6 : 8}>
																	<Dropdown
																		dataUrl={`item/location/${editReportData.location_id}?report_type=serviceinspection`}
																		label="Item"
																		keyField="item_id"
																		valueField="item_name"
																		value={item.item_id || ""}
																		fullWidth
																		size="small"
																		disabled={!Utils.checkAccess(3)}
																		onChange={(e) => {
																			editReportData.items[index].item_id = e.target.value;
																			setEditReportData((prev) => ({...prev, items: editReportData.items}))
																			}
																		}
																	/>
																</Grid>
																<Grid item xs={12} md={2}>
																	<TextField
																		label="Qty"
																		type="number"
																		placeholder="Qty"
																		fullWidth
																		size="small"
																		value={item.quantity || ""}
																		disabled={!Utils.checkAccess(3)}
																		onChange={(e) => {
																			editReportData.items[index].quantity = e.target.value;
																			setEditReportData((prev) => ({...prev, items: editReportData.items}))
																			}
																		}
																		inputProps={{ min: 0 }}
																	/>
																</Grid>
																<Grid item xs={12} md={2}>
																	<Dropdown
																		data={unitData}
																		label="Unit"
																		keyField="unit_name"
																		valueField="unit_name"
																		value={item.unit || ""}
																		fullWidth
																		size="small"
																		disabled={!Utils.checkAccess(3)}
																		onChange={(e) => {
																			editReportData.items[index].unit = e.target.value;
																			setEditReportData((prev) => ({...prev, items: editReportData.items}))
																			}
																		}
																	/>
																</Grid>
																<Grid item xs={12} md={2}>
																{
																	(Utils.checkAccess(3)) && (
																		<Button
																			aria-label="delete"
																			color="error"
																			size="small"
																			sx={{height:"100%"}}
																			variant="contained"
																			disableElevation
																			fullWidth
																			onClick={() => {
																				if(!editReportData.items[index].service_inspection_report_item_id) {
																					// TODO: Remove objects from array since its not stored in DB
																					editReportData.items.splice(index, 1);
																					setEditReportData((prev) => ({...prev, items: editReportData.items}))
																				}
																				else {
																					editReportData.items[index].delete = true;
																					setEditReportData((prev) => ({...prev, items: editReportData.items}));
																				}
																			}}
																		>
																			<CancelIcon fontSize="small"/>
																		</Button>
																	)
																}
																</Grid>
															</Grid>
														</Paper>
													))
												)
											}
											</Grid>
										</Grid>
					        </AccordionDetails>
					      </Accordion>
							</Grid>
							<Grid item xs={12}>
								<FormGroup row={true}>
									<FormControlLabel control={
										<Checkbox onChange={(e) => setEditReportData((prev) => ({...prev, tubing_connections: e.target.checked}))} />
										}
										checked={(editReportData && editReportData.tubing_connections) ? true : false}
										label="Tubing Connections"
									/>
									<FormControlLabel control={
										<Checkbox onChange={(e) => setEditReportData((prev) => ({...prev, electrical_connections: e.target.checked}))} />
										}
										checked={(editReportData && editReportData.electrical_connections) ? true : false}
										label="Electrical Connections"
									/>
									<FormControlLabel control={
										<Checkbox onChange={(e) => setEditReportData((prev) => ({...prev, cleaning_nozzles: e.target.checked}))} />
										}
										checked={(editReportData && editReportData.cleaning_nozzles) ? true : false}
										label="Cleaning Nozzles"
									/>
								</FormGroup>
							</Grid>
							<Grid item xs={12}>
								<Dropdown
									dataUrl="user"
									label="Site Inspection By"
									keyField="user_info_id"
									valueField="full_name"
									fullWidth
									multiple
									value={(editReportData && editReportData.site_inspection_by) ? editReportData.site_inspection_by : []}
									onChange={(e) => setEditReportData((prev) => ({...prev, site_inspection_by: e.target.value}))}
									/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									label="Remarks"
									type="text"
									placeholder="Additional remarks"
									variant="filled"
									multiline
									maxRows={4}
									fullWidth
									autoComplete="off"
									value={(editReportData && editReportData.remarks) ? editReportData.remarks : ""}
									onChange={(e) => setEditReportData((prev) => ({...prev, remarks: e.target.value}))}
								/>
							</Grid>
							<Grid container item xs={12} justifyContent="flex-end">
								<Button
									type="submit"
									color="success"
									variant="contained"
									disableElevation
									sx={{textTransform: 'none'}}
									>
									Submit
								</Button>
								<Button
									type="button"
									color="info"
									variant="contained"
									disableElevation
									sx={{textTransform: 'none', marginLeft: 1}}
									onClick={handleCloseEditModal}
									>
									Close
								</Button>
							</Grid>
						</Grid>
					</Box>
        </Paper>
      </Modal>
		</>
	);
}

export default ServiceInspectionReport;
