import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box'

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import ServiceInspectionReport from './ServiceInspectionReport';
import TreatmentServiceReport from './TreatmentServiceReport';
import ConsolidatedReport from './ConsolidatedReport';

const Reporting = (props) => {
	const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
		<>
	    <Box
				display="flex"
				justifyContent="center"
				sx={{ width: '100%', mb: 4 }}
				>
	      <Tabs
					variant="scrollable"
		  		scrollButtons="auto"
					value={value}
					onChange={handleChange}
					>
	        <Tab label="Service Inspection Reports" />
	        <Tab label="Bio-Treatment Service Reports" />
	      </Tabs>
	    </Box>
			{
				(value === 0) ? (
					<ServiceInspectionReport />
				) :
				(value === 1) ? (
					<TreatmentServiceReport />
				) : (<></>)
			}
		</>
  );
}

export default Reporting;
