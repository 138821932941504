import React from 'react';

import Container from '@mui/material/Container';

import Header from './Header';

function wrapper(component) {
	return (
		<Container maxWidth={false}>
				<Header component={component}/>
		</Container>
	);
}

export default wrapper;
