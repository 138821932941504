import React, { useState, useEffect, useRef, createRef, useCallback } from 'react';
import { toast } from 'react-toastify';

import { useTheme } from '@mui/material/styles';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CircularProgress from '@mui/material/CircularProgress';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import EmailIcon from '@mui/icons-material/Email';

import StripedDataGrid from '../../components/StripedDataGrid';

import Config from '../../components/Config';

/*
Props:
- open: true,false - Controls whether modal is open or closed
- customerID: number - Defines the customer report history list to load from
- onCloseModal: function - Defines the callback function to invoke when modal is closing
*/
const CustomerReportHistoryList = (props) => {
	const theme = useTheme();

	const [openModal, setOpenModal] = useState(false);
	const [reportTabValue, setReportTabValue] = useState(0);
	const [reportingSIDataGridPageSize, setReportingSIDataGridPageSize] = useState(25);
	const [reportingTSDataGridPageSize, setReportingTSDataGridPageSize] = useState(25);

	const [reportingSIData, setReportingSIData] = useState([]);
	const [reportingTSData, setReportingTSData] = useState([]);
	const [editReportingSIDataSelection, setEditReportingSIDataSelection] = useState([]);
	const [editReportingTSDataSelection, setEditReportingTSDataSelection] = useState([]);
	const [showSendingLoadingProgress, setShowSendingLoadingProgress] = useState(false);

	const [openSendReportConfirmationDialog, setOpenSendReportConfirmationDialog] = useState(false);
  const handleCloseSendReportConfirmationDialog = () => {
    setOpenSendReportConfirmationDialog(false);
  };

	const modalStyle = {
		maxHeight: '100%',
		overflow: 'auto',
	  position: 'absolute',
	  top: '50%',
	  left: '50%',
	  transform: 'translate(-50%, -50%)',
		width: '60%',
		[theme.breakpoints.down('sm')]: {
      width: '90%',
    },
		maxWidth: '1000px',
	  p: 4,
	};

	const populateReportingData = (customerID) => {
		const options = {
			method: "GET",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include'
		};

		// Populate service inspection report data
		fetch(Config.server_url + `reporting/serviceinspection?sent_report_only=true&customer_id=${customerID}`, options)
		.then(res => {
			if(!res.ok){
				res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
				return undefined;
			}
			return res.json();
		})
		.then(res => {
			if(res === undefined){
				return;
			}
			res.forEach((part, index, arr) => {
			  arr[index].id = arr[index].service_inspection_report_id;

				let addressArr = [arr[index].block_no, arr[index].address, arr[index].unit_no];
				let addressArr2 = [((arr[index].postal_code) ? `, Singapore ${arr[index].postal_code}` : ""), ((arr[index].team_name) ? `, ${arr[index].team_name}` : "")];
				let addressStr = addressArr.join(' ') + addressArr2.join('');
				arr[index].address_dg = addressStr;
			});

			setReportingSIData(res);
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});

		// Populate treatment service report data
		fetch(Config.server_url + `reporting/treatmentservice?sent_report_only=true&customer_id=${customerID}`, options)
		.then(res => {
			if(!res.ok){
				res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
				return undefined;
			}
			return res.json();
		})
		.then(res => {
			if(res === undefined){
				return;
			}
			res.forEach((part, index, arr) => {
			  arr[index].id = arr[index].treatment_service_report_id;

				let addressArr = [arr[index].block_no, arr[index].address, arr[index].unit_no];
				let addressArr2 = [((arr[index].postal_code) ? `, Singapore ${arr[index].postal_code}` : ""), ((arr[index].team_name) ? `, ${arr[index].team_name}` : "")];
				let addressStr = addressArr.join(' ') + addressArr2.join('');
				arr[index].address_dg = addressStr;
			});

			setReportingTSData(res);
		})
		.catch(err => {
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});
	};

	const sendReport = () => {
		setShowSendingLoadingProgress(true);
		// Send report based on the type of report defined
		let url = Config.server_url + `email/report/customer/${props.customerID}/resend/`
		let body = JSON.stringify({
			service_inspection: editReportingSIDataSelection,
			treatment_service: editReportingTSDataSelection,
		});

		const options = {
			method: "POST",
			headers: {'Content-Type': 'application/json'},
			credentials: 'include',
			body: body,
		};

		fetch(url, options)
		.then(res => {
			setShowSendingLoadingProgress(false);
			if(!res.ok){
				return res.json().then(res => {
					toast.error(<div>{res.message}</div>)// Display error
				});
			}
			// Success
			toast.success(<div>Successfully emailed customer.</div>);
			handleCloseSendReportConfirmationDialog();
		})
		.catch(err => {
			setShowSendingLoadingProgress(false);
			console.log(err);
			toast.error(<div>Something went wrong.</div>)// Display error
		});
	};

	if(props.open && !openModal){
		populateReportingData(props.customerID);
		setOpenModal(true);
	}

	const handleCloseModal = () => {
		setReportingSIData([]);
		setReportingTSData([]);
		props.onCloseModal();
    setOpenModal(false);
  };

	const reportColumns = [
		{
	    field: 'serial_no',
	    headerName: 'Serial No',
	  },
		{
	    field: 'address_dg',
	    headerName: 'Address',
			flex: 1,
			minWidth: 150,
	  },
	];

  return (
		<>
		<Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={modalStyle}>
				<Grid container sx={{mb: 2}}>
					<Grid item xs={12}>
		        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{mr: 1}}>
		          {"Reports History"}
		        </Typography>
						<small style={{color: theme.palette.hint.main}}>Reports that have been sent will appear here.</small>
					</Grid>
				</Grid>
				<Grid
					container
					spacing={2}
					>
					<Grid item xs={12}>
						{/* Tabs */}
						<Box sx={{ width: '100%', mb: 4 }}>
							<Tabs
								value={reportTabValue}
								onChange={(event, newValue) => {
									setReportTabValue(newValue);
								}}
								variant="fullWidth"
								centered>
								<Tab label="Service Inspection Report" />
								<Tab label="Treatment Service Report" />
							</Tabs>
						</Box>

						{
							(reportTabValue === 0) ? (
								<div style={{ height: '500px' }}>
									<StripedDataGrid
										rows={reportingSIData}
										columns={reportColumns}
										pageSize={reportingSIDataGridPageSize}
										onPageSizeChange={(newPageSize) =>
				        			setReportingSIDataGridPageSize(newPageSize)
				    				}
										pagination
										rowsPerPageOptions={[25, 50, 100]}
										checkboxSelection
										disableSelectionOnClick
										selectionModel={editReportingSIDataSelection}
										onSelectionModelChange={(selectionModel) => {
											setEditReportingSIDataSelection(selectionModel);
										}}
										sx={{
											'.MuiDataGrid-columnSeparator': {
												display: 'none',
											},
											'&.MuiDataGrid-root': {
												border: 'none',
											},
										}}
										getRowClassName={(params) =>
											params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
										}
									/>
								</div>
							) :
							(reportTabValue === 1) ? (
								<div style={{ height: '500px' }}>
									<StripedDataGrid
										rows={reportingTSData}
										columns={reportColumns}
										pageSize={reportingSIDataGridPageSize}
										onPageSizeChange={(newPageSize) =>
				        			setReportingSIDataGridPageSize(newPageSize)
				    				}
										pagination
										rowsPerPageOptions={[25, 50, 100]}
										checkboxSelection
										disableSelectionOnClick
										selectionModel={editReportingTSDataSelection}
										onSelectionModelChange={(selectionModel) => {
											setEditReportingTSDataSelection(selectionModel);
										}}
										sx={{
											'.MuiDataGrid-columnSeparator': {
												display: 'none',
											},
											'&.MuiDataGrid-root': {
												border: 'none',
											},
										}}
										getRowClassName={(params) =>
											params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
										}
									/>
								</div>
							) : <></>
						}
					</Grid>
					<Grid container item xs={12} justifyContent="flex-end">
						<Button
							color="warning"
							variant="contained"
							disableElevation
							onClick={() => setOpenSendReportConfirmationDialog(true)}
							sx={{textTransform: 'none'}}>
							Send
						</Button>
						<Button
							type="button"
							color="info"
							variant="contained"
							disableElevation
							sx={{textTransform: 'none', marginLeft: 1}}
							onClick={handleCloseModal}
							>
							Close
						</Button>
					</Grid>
				</Grid>
      </Paper>
    </Modal>
		{/* Send Confirmation Dialog */}
		<Dialog
        open={openSendReportConfirmationDialog}
        onClose={handleCloseSendReportConfirmationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      <DialogTitle id="alert-dialog-title">
        {"Are you sure?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This will send the report to the customer immediately.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
			{
				(showSendingLoadingProgress) ?
				(
					<Grid container item justifyContent="center">
						<CircularProgress sx={{mr: 2}}/><p>Sending...</p>
					</Grid>
				) :
				(
				<>
        <Button color="warning" variant="contained" disableElevation onClick={() => sendReport()} sx={{textTransform: 'none'}}>Send</Button>
        <Button onClick={handleCloseSendReportConfirmationDialog} autoFocus sx={{textTransform: 'none'}}>
          Close
        </Button>
				</>
				)
			}
      </DialogActions>
    </Dialog>
		</>
  );
}

export default CustomerReportHistoryList;
